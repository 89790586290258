

import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Helmet } from "react-helmet";
import Iframe from 'react-iframe'
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

export default function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [wyslanoWiadomosc, setwyslanoWiadomosc] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6kxdm78",
        "template_m6wdswk",
        e.target,
        "mx4eNYcU6K_qE927r"
      )
      .then(
        (result) => {
          console.log(result.text);
          setwyslanoWiadomosc(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const Wyslano = () => {
    return (
      <div>
        {wyslanoWiadomosc ? (
          <Alert
            sx
          // action={
          //   <Button onClick={refreshPage} color="inherit" size="small">
          //     X
          //   </Button>
          // }
          >
            Wiadomość wysłana!
          </Alert>
        ) : null}
      </div>
    );
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 2, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Typography
        sx={{ maxWidth: 1 }}
        component="h5"
        variant="h5"
        align="center"
        color="black"
        gutterBottom
      >
        Formularz rezerwacyjny
        <br />
      </Typography>
      <Container sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>

        <Typography
          sx={{ maxWidth: 1 }}
          component="h6"
          variant="h6"
          align="center"
          color="black"
          gutterBottom
        >

          <br />
          Prosimy o podanie terminu rezerwacji, ilości pomieszczeń oraz dodatkowych wymagań. 
         
          <br/>
          Prosimy równiez o określenie w jak celu dokunujecie Państwo rezerwacji.
          <br/>
          Wysłanie zapytania nie jest równoznaczne z dokonaniem rezerwacji.
        </Typography>
        <Box sx={{}} component="form" onSubmit={sendEmail} noValidate={validated}>
          <br />


          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              data-aos="fade-up"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="from_email"
                autoComplete="email"
              />
            </Grid>{" "}
            <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              data-aos="fade-up"
            >
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Sala/Całość "
                name="sala"

              />
            </Grid>{" "}
         



            <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              data-aos="fade-up"
            >
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Imię i nazwisko "
                name="from_name"
                autoComplete="name"
              />
            </Grid>{" "}
            <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              data-aos="fade-up"
            >
              <TextField
                multiline
                margin="normal"
                fullWidth
                name="from_number"
                label="Numer telefonu"
                type="number"
                id="number"
              />
            </Grid>
            <Grid
              item
              key="2ff4"
              xs={12}
              sm={12}
              md={12}
              data-aos="fade-up"
            >
              <TextField
                multiline
                margin="normal"
                required
                fullWidth
                name="message"
                label="Wiadomość"
                type="text"
                id="text"
                rows={4}
              />
              </Grid>
              <Grid
              item
              key="2f4"
              xs={12}
              sm={12}
              md={6}
              data-aos="fade-up"
            >
              Rezerwacja od
              <DatePicker

                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Grid>{" "}

            <Grid
              item
              key="223f4"
              xs={12}
              sm={12}
              md={6}
              
            >
              Rezerwacja do 
              <DatePicker

                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Grid>{" "}
              * Pola obowiązkowe.
              <br />
              <Grid
              item
              key="2fsd4"
              xs={12}
              sm={12}
              md={12}
              
            >
              <Wyslano />

              <Button

                type="submit"
                color="secondary"
                variant="contained"
                large
                sx={{ mt: 3, mb: 2 }}
              >
                Wyślij zapytanie
              </Button>
              <br />
              <p className="samllText" align="justify">
                Administratorem danych osobowych są Jakub Kozubek, Jan
                Matusiak, prowadzący wspólnie
                działalność gospodarczą jako Kozubek Matusiak
                Radcowie Prawni spółka cywilna,
                adres: ul. Piwna 15/3, 30-527 Kraków; NIP: 9452267798;
                REGON: 524238078. Dane osobowe są przetwarzane w celu
                obsługi zapytania, w szczególności prowadzenia
                korespondencji w tym zakresie, a także w celu ustalenia,
                dochodzenia lub obrony przed potencjalnymi roszczeniami.
                Więcej informacji na temat przetwarzania danych
                osobowych znajduje się w Polityce Prywatności i Cookies,
                do których link znajduje się poniżej.
              </p>
              </Grid>{" "}
          </Grid>
        </Box>
        <br /><br /><br />
        <Box sx={{ height: 20 }} />
        <Typography
          sx={{ maxWidth: 1 }}
          component="h6"
          variant="h6"
          align="justify"
          color="black"
          gutterBottom
        >
          Poniżej znajduje się aktualny kalendarz rezerwacji, prosimy o sprawdzenie dostępności lokalu w okresie Państwa interesującym
        </Typography>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}>
          <Iframe url="https://calendar.google.com/calendar/embed?src=066403ee27933eeaedb0b473943f104141a55ace224b823416baeaea8714c5c4%40group.calendar.google.com&ctz=Europe%2FWarsaw"
            width="440px"
            height="320px"
            id=""
            className=""
            display="block"
            position="relative" />
          {/* <iframe src="" style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe> */}

        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}>
          <Iframe url="https://calendar.google.com/calendar/embed?src=066403ee27933eeaedb0b473943f104141a55ace224b823416baeaea8714c5c4%40group.calendar.google.com&ctz=Europe%2FWarsaw"
            width="800px"
            height="320px"
            id=""
            className=""
            display="block"
            position="relative" />
          {/* <iframe src="" style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe> */}

        </Box>


      </Container>
      <br /><br /><br /><br />
      <Helmet>

        <title>
          Jakub Kozubek
        </title>
        <meta name="description" content="Kancelaria KMT Jakub Kozubek obsuga prawna przedsiębiorców, podmiotów gospodarczych i indywidualnych klientów"
        />
        <meta name="keywords" content="KMT, Kozubek Matusiak  Radcowie Prawni spółka cywilna, KMT Kozubek Matusiak , KMT Legal, KMT Kozubek Matusiak 
    Jan Matusiak, KMT,  Obsługa prawna przedsiębiorców w Krakowie, Prawnicy w Krakowie, Radcowie prawni w Krakowie, Jakub Kozubek"/>
        <link rel="canonical" href="https://kmtlegal.pl/Jakub%20Kozubek" />
      </Helmet>

    </React.Fragment>
  );
}
