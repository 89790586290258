import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/icons-material/Grading";
import Com from "@mui/icons-material/ConnectWithoutContact";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import NavPc from "./navPcEn";
import { Helmet } from "react-helmet";
const text = [
  {
    one: "sadsad",
    onePirce: "sadsad",
    two: "sadsad",
    twoPirce: "sadsad",
    three: "sadsad",
    threePirce: "sadsad",
    four: "sadsad",
    fourPirce: "sadsad",
    five: "sadsad",
    fivePirce: "sadsad",
    six: "sadsad",
    sixPirce: "sadsad",
  },
];

function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>

        <Container maxWidth="lg">

          <Box
            sx={{

              minHeight: "80vh",
            }}
          >
            <Container
              data-aos="fade-down"
              sx={{
                pt: 5,
                pb: 22,
                // backgroundColor: "secondary.main",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color=""
                gutterBottom
                sx={{
                  backgroundColor: "primary",
                }}
              >
                Cennik
                <hr />
              </Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Sala nr 1 - 400 PLN/dzień</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Sale nr 2, 3 i 4 - 550 PLN/dzień</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Cała piwnica - 800 PLN/dzień</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Wynajem na ponad 3 dni -10%</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Wynajem na ponad 7 dni -30%</Typography>
            </Container>
            <Typography component="h1"
                variant="h5" align="center" gutterBottom>Dodatkowe informacje jak fvat itp.</Typography>
          </Box>
          <Helmet>
            <title>O Nas</title>
            <meta name="description" content="O KMT" />
            <link
              rel="canonical"
              href="http://kmtlegal.pl/ONas"
            />
          </Helmet>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
